import { SEO } from '@renderbus/common/components'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../molecules/layout'
import Helmet from 'react-helmet'
import {
  ContentContainer,
  BannerWrapper,
  ContentImg,
  ContentMbImg,
  Section,
  SectionTitleWrapper,
  RechargeCardWrapper,
  RechargeInputWrapper,
  RechargeInput,
  CurrencySymbol,
  RechargeInputTip,
  RechargeInputLabel,
  RechargeRule,
  RechargeTitle,
  RechargeRuleItem,
  InviteWrapper,
  InviteRule,
  IniteRuleItem,
  InviteRuleTitle,
  BannerImg,
  SectionTitleTip,
  ImgButton,
  RechargeCard,
  RechargeCardContent,
  Title,
  BannerHeaderWrap,
  TitleSecond,
  TitleTip,
  InputWarningSpan,
  GoldImg,
  Sidebar,
  TitleThird,
} from './3d-rendering-special-offer.atom'
import InviteTitleImg from '../images/picture-giveback-old-user/invite-title.png'
import AmountDefault from '../images/picture-giveback-old-user/amount-default.png'
import AmountHotDefault from '../images/picture-giveback-old-user/amount-hot-default.png'
import AmountHover from '../images/picture-giveback-old-user/amount-hover.png'
import AmountHotHover from '../images/picture-giveback-old-user/amount-hot-hover.png'
import AmountMbDefault from '../images/picture-giveback-old-user/amount-mb-default.png'
import AmountHotMbDefault from '../images/picture-giveback-old-user/amount-hot-mb-default.png'
import AmountMbHover from '../images/picture-giveback-old-user/amount-mb-hover.png'
import AmountHotMbHover from '../images/picture-giveback-old-user/amount-hot-mb-hover.png'
import StarImage from '../images/picture-giveback-old-user/star.png'
import StepImage from '../images/picture-giveback-old-user/step.png'
import ButtonDefault from '../images/picture-giveback-old-user/button-default.png'
import ButtonHover from '../images/picture-giveback-old-user/button-hover.png'
import BannerBg from '../images/picture-giveback-old-user/banner-bg.png'
import BannerBgMb from '../images/picture-giveback-old-user/banner-bg-mobile.png'
import Dragon from '../images/picture-giveback-old-user/dragon.png'
import DragonMb from '../images/picture-giveback-old-user/dragon-mobile.png'
import Diamond from '../images/picture-price-gift/activity-page/diamond.png'
import Gold from '../images/picture-giveback-old-user/gold.png'
import SidebarTitle from '../images/picture-giveback-old-user/sidebar-title.png'
import TitleImg from '../images/picture-giveback-old-user/title.png'
import { isTest } from '@renderbus/common/service'

const rechargeRules = [
  '达标条件：180天以上未登录的效果图用户；',
  '福利：2024年05月09日起登录即可领取25元通用渲染券，一个月会员权益；',
  `充值福利：充值500元赠送300元通用券，充值2000元赠送1500元通用券，充值5000元赠送4000元通用券。<br/>通用券有效期6个月;`,
  '活动期内，每个用户仅可参与一次；',
  '赠送的通用券为即时到账，请及时查看账户余额，有问题联系客服；',
  '本活动最终解释权归深圳市瑞云科技股份有限公司所有。',
]

const inviteRules = [
  '好友邀请仅限注册Renderbus瑞云渲染效果图用户；',
  '邀请好友获得的渲染券有效期1个月。',
]
const rechargeLevels = [
  { amount: 500, coupon: 300 },
  { amount: 2000, coupon: 1500 },
  { amount: 5000, coupon: 4000 },
]

class threeDRenderingSpecialOffer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hoverRecharge: false,
      hoverInvite: false,
      rechargeAmount: 5000,
    }
  }
  setHoverRechargeCard(amount) {
    setTimeout(() => {
      this.setState({
        rechargeAmount: amount,
      })
    }, 10)
  }
  setHoverRecharge(isHover = true) {
    setTimeout(() => {
      this.setState({
        hoverRecharge: isHover,
      })
    }, 10)
  }
  setHoverInvite(isHover = true) {
    setTimeout(() => {
      this.setState({
        hoverInvite: isHover,
      })
    }, 10)
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    const onRechargeChange = e => {
      const amount = e.target.value
      this.setState({
        rechargeAmount: isNaN(amount) || !amount ? '' : Number(amount),
      })
    }
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/picture-price-gift/activity-page/bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/picture-price-gift/activity-page/bg-mobile.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb, content }) => (
          <Layout location={location} topThreeShare={topThreeShare}>
            <SEO
              title='【效果图渲染用户专享】老用户回归感恩回馈专属福利活动-瑞云效果图渲染'
              keywords='效果图渲染, 瑞云效果图渲染, 渲染效果图优惠'
              description='瑞云效果图渲染老用户专属福利上线啦！用户可领取25元渲染券以及1个月会员权益，渲染效果图优惠福利多多，欢迎登录解锁更多新惊喜！'
            />
            <Helmet>
              <script>
                {`
                  var _czc = _czc || [];
                  (function () {
                    var um = document.createElement("script");
                    um.src = "https://s4.cnzz.com/z.js?id=1281345904&async=1";
                    var s = document.getElementsByTagName("script")[0];
                    s.parentNode.insertBefore(um, s);
                  })();
                `}
              </script>
            </Helmet>

            <ContentContainer theme='black' id='price-middle'>
              <BannerWrapper>
                <BannerImg src={BannerBg} className='hideMDDown' />
                <BannerImg src={BannerBgMb} className='hideSMUp' />
                <BannerHeaderWrap>
                  <GoldImg src={Gold} />
                  <TitleTip>
                    <img src={Diamond} alt='diamond' />
                    效果图回归用户专享
                  </TitleTip>
                  <ContentImg src={Dragon} />
                  <ContentMbImg src={DragonMb} />
                  <Title>
                    <img src={TitleImg} alt='' />
                  </Title>
                  <TitleSecond>
                    登录即可领取<span>25元渲染券及1个月会员权益</span>
                  </TitleSecond>
                  <TitleThird>达标条件：180天以上未登录的效果图用户</TitleThird>
                </BannerHeaderWrap>
                <Section top={600} mbTop={580} className='section-mobile-card-1'>
                  <RechargeCardWrapper>
                    {rechargeLevels.map(item => (
                      <RechargeCard
                        key={item.amount}
                        onMouseOver={() => this.setHoverRechargeCard(item.amount)}
                      >
                        <img
                          className='recharge-card'
                          src={
                            this.state.rechargeAmount === item.amount
                              ? item.amount === 5000
                                ? AmountHotHover
                                : AmountHover
                              : item.amount === 5000
                              ? AmountHotDefault
                              : AmountDefault
                          }
                          isHover={this.state.rechargeAmount === item.amount}
                          alt='效果图云渲染多少钱一张？效果图渲染限时钜惠!'
                        />
                        <img
                          className='recharge-card-mb'
                          src={
                            this.state.rechargeAmount === item.amount
                              ? item.amount === 5000
                                ? AmountHotMbHover
                                : AmountMbHover
                              : item.amount === 5000
                              ? AmountHotMbDefault
                              : AmountMbDefault
                          }
                          isHover={this.state.rechargeAmount === item.amount}
                          alt='效果图云渲染多少钱一张？效果图渲染限时钜惠!'
                        />
                        <RechargeCardContent
                          style={{
                            color:
                              this.state.rechargeAmount === item.amount ? '#6D4303' : '#FFFFFF',
                          }}
                        >
                          <div className='card-amount'>充值{item.amount}元得</div>
                          <div className='card-total'>
                            ¥<span>{item.amount + item.coupon}</span>
                          </div>
                          <div
                            className='card-coupon'
                            style={{
                              color:
                                this.state.rechargeAmount === item.amount
                                  ? 'rgba(255, 225, 158, 1)'
                                  : '#FFFFFF',
                            }}
                          >
                            含赠送{item.coupon}元通用券
                          </div>
                        </RechargeCardContent>
                      </RechargeCard>
                    ))}
                  </RechargeCardWrapper>
                  <RechargeInputWrapper>
                    <RechargeInputLabel>充值金额</RechargeInputLabel>
                    <CurrencySymbol>¥</CurrencySymbol>
                    <RechargeInput
                      value={this.state.rechargeAmount}
                      onChange={onRechargeChange}
                      isWarning={
                        this.state.rechargeAmount < 5 || this.state.rechargeAmount > 200000
                      }
                    />
                    <RechargeInputTip>
                      (余额到账 ¥{this.state.rechargeAmount || 0}
                      <span className='divider' />
                      赠送
                      {rechargeLevels
                        .concat()
                        .reverse()
                        .find(item => this.state.rechargeAmount >= item.amount)?.coupon || 0}
                      元通用券)
                    </RechargeInputTip>
                    {(this.state.rechargeAmount < 5 || this.state.rechargeAmount > 200000) && (
                      <InputWarningSpan>请输入5.00-200,000.00之间数值</InputWarningSpan>
                    )}
                  </RechargeInputWrapper>
                  <ImgButton
                    id='xgt-monthly-promotion'
                    as='a'
                    href={`${
                      isTest()
                        ? 'https://account-pre.renderbus.com'
                        : 'https://account.renderbus.com'
                    }/center/user/topUp`}
                    target='_blank'
                    onMouseOver={() => this.setHoverRecharge(true)}
                    onMouseOut={() => this.setHoverRecharge(false)}
                    onClick={() => {
                      window['_czc'].push(['_trackEvent', '按钮点击', 'ljcz', '立即充值'])
                    }}
                  >
                    <p>立即充值</p>
                    <img
                      src={this.state.hoverRecharge ? ButtonHover : ButtonDefault}
                      alt='效果图云渲染多少钱一张？效果图渲染限时钜惠!'
                    />
                  </ImgButton>
                  <RechargeRule>
                    <div>
                      <RechargeTitle>活动规则</RechargeTitle>
                      {rechargeRules.map((item, index) => (
                        <RechargeRuleItem key={item}>
                          <img src={StarImage} alt='效果图云渲染多少钱一张？效果图渲染限时钜惠!' />
                          {/* <span dangerouslySetInnerHTML={{ __html: item }}></span> */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                typeof window !== 'undefined' && window.innerWidth < 600
                                  ? item.replace(/<br\/>/g, '')
                                  : item,
                            }}
                          ></span>
                        </RechargeRuleItem>
                      ))}
                    </div>
                  </RechargeRule>
                </Section>
              </BannerWrapper>
              <Section top={146} height={1204} mbTop={137}>
                <SectionTitleWrapper>
                  <img src={InviteTitleImg} alt='邀请好友，双方免费渲染' />
                </SectionTitleWrapper>
                <SectionTitleTip>
                  <div className='section-title-tip section-title-tip-sub'>
                    复制专属链接邀请好友注册实名，邀请人立得
                    <span className='section-title-tip-amount'>10元</span>无门槛渲染券!
                    <span className='section-title-tip-attention'>上不封顶!</span>
                  </div>
                  <div className='section-title-tip section-title-tip-sub'>
                    被邀请好友注册实名后可获得<span className='section-title-tip-amount'>10元</span>
                    无门槛渲染券
                  </div>
                </SectionTitleTip>
                <InviteWrapper>
                  <img src={StepImage} alt='邀请好友，双方免费渲染' />
                  <ImgButton
                    as='a'
                    href={`${
                      isTest()
                        ? 'https://account-pre.renderbus.com'
                        : 'https://account.renderbus.com'
                    }/center/user/inviteRewards`}
                    target='_blank'
                    className='invite-button-style'
                  >
                    <p>邀请好友</p>
                    <img
                      src={this.state.hoverInvite ? ButtonHover : ButtonDefault}
                      alt='效果图云渲染多少钱一张？效果图渲染限时钜惠!'
                      onMouseOver={() => this.setHoverInvite(true)}
                      onMouseOut={() => this.setHoverInvite(false)}
                    />
                  </ImgButton>
                </InviteWrapper>
                <InviteRule>
                  <InviteRuleTitle>活动规则</InviteRuleTitle>
                  {inviteRules.map(item => (
                    <IniteRuleItem key={item}>
                      <img src={StarImage} alt='效果图云渲染多少钱一张？效果图渲染限时钜惠!' />
                      {item}
                    </IniteRuleItem>
                  ))}
                </InviteRule>
              </Section>
              {/* <Sidebar className='hideMDDown'>
                <img src={SidebarTitle} alt='' />
              </Sidebar> */}
            </ContentContainer>
          </Layout>
        )}
      />
    )
  }
}

export default threeDRenderingSpecialOffer
